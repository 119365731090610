enum Actions {
  // action types
  PER_PAGE = "perPageAction",
  PAGE_NUMBER = "pageNumberAction",
  SEARCH = "searchAction",
  ASSET_SEARCH = "assetSearchAction",
  ORDER_SEARCH = "orderSearchAction",
  COMMENT_SEARCH = "commentUserSearchAction",
}

enum Mutations {
  // mutation types
  SET_DEFAULT_PER_PAGE = "defaultPerPageMutation",
  SET_PER_PAGE = "perPageMutation",
  SET_PAGE_NUMBER = "pageNumberMutation",
  SET_SEARCH = "searchMutation",
  SET_ASSET_SEARCH = "assetSearchMutation",
  SET_ORDER_SEARCH = "orderSearchMutation",
  CLEAR_SEARCH = "clearMutation",
  SET_COMMENT_SEARCH = "commentUserSearchMutation",
}

export { Actions, Mutations };
