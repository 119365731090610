import { createI18n } from "vue-i18n/index";
const options = {
  legacy: false,
  locale: "en",
  fallbackLocale:"en",
  globalInjection: true,
  missingWarn: false,
  fallbackWarn: false
};
const i18n = createI18n(options);
export default i18n;
