import Layout2 from "@/layout/Layout.vue";

export default [
  {
    path: "/assets",
    component: Layout2,
    children: [
      {
        path: "/assets",
        name: "assets",
        component: () => import("@/views/assets/List.vue"),
        meta: {
          auth: true,
          section: "assets",
          allowedPermission: ["readAsset"],
          module: "assets",
        },
      },
      {
        path: "/assets/create",
        name: "add-asset",
        component: () => import("@/views/assets/AddEditAsset.vue"),
        meta: {
          auth: true,
          section: "assets",
          allowedPermission: ["createUpdateAsset", "updateAsset"],
          module: "assets",
        },
      },
      {
        path: "/assets/edit/:id",
        name: "edit-asset",
        component: () => import("@/views/assets/AddEditAsset.vue"),
        meta: {
          auth: true,
          section: "assets",
          allowedPermission: ["updateAsset", "createUpdateAsset"],
          module: "assets",
        },
      },
      {
        path: "/assets/assetprice/:id",
        name: "asset-price",
        component: () => import("@/views/assets/AssetPrice.vue"),
        meta: {
          auth: true,
          section: "assets",
          allowedPermission: ["readAsset"],
          module: "assets",
        },
      },
      {
        path: "/assets/import-assets-price",
        name: "import-assets-price",
        component: () => import("@/views/assets/ImportAssetPrice.vue"),
        meta: {
          auth: true,
          section: "assets",
          allowedPermission: ["updateAsset", "createUpdateAsset"],
          module: "assets",
        },
      },
    ],
  },
];
