import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/users",
  component: Layout2,
  children: [{
    path: "/users",
    name: "admins-list",
    component: () => import("@/views/users/List"),
    meta: {
      auth: true,
      section: "admin",
      allowedPermission: ["readUser"],
      module: "users"
    }
  }, {
    path: "create",
    name: "create-admin",
    component: () => import("@/views/users/AddEditUser"),
    meta: {
      auth: true,
      section: "admin",
      allowedPermission: ["createUpdateUser"],
      module: "users"
    }
  }, {
    path: "edit/:id",
    name: "edit-admin",
    component: () => import("@/views/users/AddEditUser"),
    meta: {
      auth: true,
      section: "admin",
      allowedPermission: ["createUpdateUser", "updateUser"],
      module: "users"
    }
  }, {
    path: "change-password",
    name: "change-password",
    component: () => import("@/views/users/ChangePassword"),
    meta: {
      auth: true,
      section: "admin",
      allowedPermission: ["createUpdateUser", "updateUser"],
      module: "users"
    }
  }]
}];