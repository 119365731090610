import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/activity",
  component: Layout2,
  children: [{
    path: "/activity",
    name: "activity",
    component: () => import("@/views/activity/List.vue"),
    meta: {
      auth: true,
      allowedPermission: ["readActivity"],
      module: "activity",
      section: "settings"
    }
  }, {
    path: "/activity/create",
    name: "add-activity",
    component: () => import("@/views/activity/AddEditActivity.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateActivity"],
      module: "activity",
      section: "settings"
    }
  }, {
    path: "/activity/edit/:id",
    name: "edit-activity",
    component: () => import("@/views/activity/AddEditActivity.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateActivity", "updateActivity"],
      module: "activity",
      section: "settings"
    }
  }]
}];