import Layout2 from "@/layout/LayoutModal.vue";

export default [{
  path: "/stores",
  component: Layout2,
  children: [{
    path: "/stores",
    name: "stores",
    component: () => import("@/views/stores/List.vue"),
    meta: {
      auth: true,
      section: "website",
      allowedPermission: ["readStore"],
      module: "stores"
    }
  }, {
    path: "/stores/create",
    name: "add-store",
    component: () => import("@/views/stores/AddEditStore.vue"),
    meta: {
      auth: true,
      section: "website",
      allowedPermission: ["createUpdateStore"],
      module: "stores"
    }
  }, {
    path: "/stores/edit/:id",
    name: "edit-store",
    component: () => import("@/views/stores/AddEditStore.vue"),
    meta: {
      auth: true,
      section: "website",
      allowedPermission: ["createUpdateStore", "updateStore"],
      module: "stores"
    }
  }, {
    path: "/stores/order-mapping/:id",
    name: "order-mapping",
    component: () => import("@/views/stores/AddEditOrderMapping.vue"),
    meta: {
      auth: true,
      section: "website",
      allowedPermission: ["updateStore"],
      module: "stores"
    }
  }
  ]
}];