import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/asset-price-groups",
  component: Layout2,
  children: [{
    path: "/asset-price-groups",
    name: "asset-price-group",
    component: () => import("@/views/asset-price-groups/List.vue"),
    meta: {
      auth: true,
      section: "assets",
      allowedPermission: ["readAsset"],
      module: "assetPriceGroups"
    }
  }, {
    path: "add",
    name: "add-price-group",
    component: () => import("@/views/asset-price-groups/AddEditAssetPriceGroup.vue"),
    meta: {
      auth: true,
      section: "assets",
      allowedPermission: ["createUpdateAsset", "updateAsset"],
      module: "assetPriceGroups"
    }
  }, {
    path: "edit/:id",
    name: "edit-price-group",
    component: () => import("@/views/asset-price-groups/AddEditAssetPriceGroup.vue"),
    meta: {
      auth: true,
      section: "assets",
      allowedPermission: ["createUpdateAsset", "updateAsset"],
      module: "assetPriceGroups"
    }
  }
  ]
}];