import Layout2 from "@/layout/Layout.vue";

export default [{ 
  path: "/reports",
  redirect: "/reports",
  component: Layout2,
  children: [
    {
      path: "/jobs-report",
      name: "jobs-report",
      component: () => import("@/views/reports/Jobs.vue"),
      meta: {
        auth: true,
        section: "reports",
        allowedPermission: ["readReports"],
        module: "reports"
      },
    },
    {
      path: "/job-activity-report",
      name: "job-activity-report",
      component: () => import("@/views/reports/JobActivity.vue"),
      meta: {
        auth: true,
        section: "reports",
        allowedPermission: ["readReports"],
        module: "reports"
      },
    },
  ]
}];