
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import GlobalConfig from "@/core/config/GlobalConfig";
import { getDefaultLanguageData } from "@/composable/i18n.js";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem("lang");

    const signOut = () => {
      const languageData = getDefaultLanguageData();
      localStorage.setItem("lang", languageData.defaultLanguageData.short_code);
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      window.location.reload();
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return GlobalConfig.siteLanguages[i18n.locale.value];
    });

    const userInfo = JSON.parse(JwtService.getLoggedInUserInfo() || "{}");
    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      userInfo,
      GlobalConfig
    };
  },
});
