import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/roles",
  component: Layout2,
  children: [{
    path: "/roles",
    name: "roles-list",
    component: () => import("@/views/roles/List.vue"),
    meta: {
      auth: true,
      section: "admin",
      allowedPermission: ["readRole"],
      module: "roles"
    }
  }, {
    path: "/roles/create",
    name: "roles-add",
    component: () => import("@/views/roles/AddEditRole.vue"),
    meta: {
      auth: true,
      section: "admin",
      allowedPermission: ["createUpdateRole"],
      module: "roles"
    }
  }, {
    path: "/roles/edit/:id",
    name: "roles-edit",
    component: () => import("@/views/roles/AddEditRole.vue"),
    meta: {
      auth: true,
      section: "admin",
      allowedPermission: ["createUpdateRole", "updateRole"],
      module: "roles"
    }
  }]
}];