import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/status",
  component: Layout2,
  children: [{
    path: "/status",
    name: "status",
    component: () => import("@/views/status/List.vue"),
    meta: {
      auth: true,
      section: "settings",
      allowedPermission: ["readStatus"],
      module: "status"
    }
  }, {
    path: "/status/create",
    name: "add-status",
    component: () => import("@/views/status/AddEditStatus.vue"),
    meta: {
      auth: true,
      section: "settings",
      allowedPermission: ["createUpdateStatus"],
      module: "status"
    }
  }, {
    path: "/status/edit/:id",
    name: "edit-status",
    component: () => import("@/views/status/AddEditStatus.vue"),
    meta: {
      auth: true,
      section: "settings",
      allowedPermission: ["createUpdateStatus", "updateStatus"],
      module: "status"
    }
  }
  ]
}];