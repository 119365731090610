import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/devices",
  component: Layout2,
  children: [{
    path: "/devices",
    name: "devices",
    component: () => import("@/views/devices/List.vue"),
    meta: {
      auth: true,
      allowedPermission: ["readDevices"],
      module: "devices",
      section: "settings",
    }
  }, {
    path: "/devices/create",
    name: "add-device",
    component: () => import("@/views/devices/AddEditDevice.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateDevice"],
      module: "devices",
      section: "settings",
    }
  }, {
    path: "/devices/edit/:id",
    name: "edit-device",
    component: () => import("@/views/devices/AddEditDevice.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateDevice", "updateDevice"],
      module: "devices",
      section: "settings",
    }
  }, {
    path: "/my-devices",
    name: "my-device",
    component: () => import("@/views/my-devices/List.vue"),
    meta: {
      auth: true,
      allowedPermission: ["myDevices", "allDevices"],
      module: "devices",
      section: "my-devices",
    }
  }]
}];