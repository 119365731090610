import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/orders",
  component: Layout2,
  children: [{
    path: "/orders",
    name: "orders-list",
    component: () => import("@/views/orders/List"),
    meta: {
      auth: true,
      allowedPermission: ["readOrder"],
      module: "orders",
      section: "orders",
      pageType: "listOrders",
    }
  },
  {
    path: "/order-products",
    name: "order-products",
    component: () => import("@/views/orders/OrderProducts"),
    meta: {
      auth: true,
      allowedPermission: ["readOrder"],
      module: "orders",
      section: "orders",
    }
  },
  {
    path: "/orders/:orderid/:productid?",
    name: "order-details",
    component: () => import("@/views/orders/Details.vue"),
    meta: {
      auth: true,
      allowedPermission: ["readOrder"],
      module: "orders",
      section: "orders",
    }
  },
  {
    path: "/archive-orders",
    name: "archive-orders",
    component: () => import("@/views/orders/List"),
    meta: {
      auth: true,
      allowedPermission: ["readOrder"],
      module: "orders",
      section: "orders",
      pageType: "archieveOrders",
    }
  },
  {
    path: "/job-board",
    name: "job-board",
    component: () => import("@/views/orders/JobBoard"),
    meta: {
      auth: true,
      allowedPermission: ["readOrder"],
      module: "orders",
      section: "orders"
    }
  },
  {
    path: "/orders/import-orders",
    name: "import-orders",
    component: () => import("@/views/orders/ImportOrders"),
    meta: {
      auth: true,
      section: "orders",
      allowedPermission: ["createUpdateOrder"],
      module: "orders",
    },
  },
  ]
}];