import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

import userRoutes from "./user";
import customerRoutes from "./customers";
import orderRoutes from "./order";
import rolesRoutes from "./role";
import storesRoutes from "./stores";
import quotesRoutes from "./quote";
import assetsRoutes from "./assets";
import auditLogsRoutes from "./audit-logs";
import predefinedComments from "./predefined-comments";
import errorRoutes from "./error";
import assetRules from "./asset-rules";
import assetPriceGroups from "./asset-price-groups";
import { useLoggedInUserRoles, useLoggedInUserPermissions } from "@/composable/global-functions.js";
import { Mutations as rememberMutation } from "@/store/enums/RememberSearchEnums";
import i18n from "@/core/plugins/i18n";
import device from "./device";
import operation from "./operation";
import status from "./status";
import sheets from "./sheets";
import inks from "./inks";
import jobs from "./job";
import activity from "./activity";
import jobWorkflow from "./job-workflow";
import templateManagement from "./templates";
import shippingMethod from "./shipping-method";
import masterPackageRoutes from "./master-package";
import siteAddressesRoutes from "./site-addresses";
import configurationSettingsRoutes from "./configuration-settings";
import configurationRoutes from "./configuration";
import formulasRoutes from "./formulas";
import reportsRoutes from "./reports";

import { useNotification } from "@/composable/global-functions.js";
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          auth: true,
          module: "dashboard"
        },
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          module: "auth"
        },
      }
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/",
    redirect: "/my-profile",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/my-profile",
        name: "my-profile",
        component: () => import("@/views/MyProfile.vue"),
        meta: {
          auth: true,
          module: "users"
        },
      },
    ]
  }
]
  .concat(customerRoutes)
  .concat(orderRoutes)
  .concat(userRoutes)
  .concat(storesRoutes)
  .concat(rolesRoutes)
  .concat(quotesRoutes)
  .concat(assetsRoutes)
  .concat(auditLogsRoutes)
  .concat(predefinedComments)
  .concat(status)
  .concat(errorRoutes)
  .concat(assetRules)
  .concat(assetPriceGroups)
  .concat(device)
  .concat(operation)
  .concat(sheets)
  .concat(inks)
  .concat(jobs)
  .concat(activity)
  .concat(templateManagement)
  .concat(jobWorkflow)
  .concat(shippingMethod)
  .concat(masterPackageRoutes)
  .concat(siteAddressesRoutes)
  .concat(formulasRoutes)
  .concat(configurationSettingsRoutes)
  .concat(configurationRoutes)
  .concat(reportsRoutes);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let userLang = localStorage.getItem("lang");
  let moduleMessages = {};
  if (typeof to.meta.module != "undefined") {
    try {
      moduleMessages = require(`@/locales/${userLang}/${to.meta.module}.json`);
    }
    catch {
      if (process.env.NODE_ENV == "development") useNotification("Please add language file for the " + to.meta.module + " module", "warning");
    }
  }
  let commonMessages = require(`@/locales/${userLang}/common.json`);
  const messages = {...moduleMessages, ...commonMessages};
  //Set message and language for i18n plugin
  i18n.global.locale.value = userLang;
  i18n.global.setLocaleMessage(userLang, messages);

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  const token = JwtService.getToken();
  if (to.matched.some(record => record.meta.auth) && !token) {
    next("/sign-in");
    return;
  }
  if (token) {
    ApiService.setHeader();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  //Check the loggedin user's roles and permissions
  let permission = useLoggedInUserPermissions();
  let role = useLoggedInUserRoles();
  if (role != "Super Admin") {
    if (to.matched.some(record => record.meta.allowedPermission)) {
      let allowedPermission = permission.filter(value => to.meta.allowedPermission.includes(value));
      if (allowedPermission.length > 0) {
        next();
        return;
      } else {
        next("/403");
        return;
      }
    } else {
      next();
    }
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  if (from.meta.module != to.meta.module) {
    store.commit(rememberMutation.CLEAR_SEARCH, (from.meta.module) ? from.meta.module : "");
  }  
});
export default router;
