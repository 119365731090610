import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/configuration",
  component: Layout2,
  children: [{
    path: "/configuration",
    name: "configuration",
    component: () => import("@/views/configuration/List.vue"),
    meta: {
      auth: true,
      section: "settings",
      allowedPermission: ["readStore", "manageSettings", "readSiteAddresses", "readTemplate"],
      module: "configurationSettings"
    }
  }
  ]
}];