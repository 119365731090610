import Layout2 from "@/layout/LayoutModal.vue";

export default [{
    path: "/site-addresses",
    component: Layout2,
    children: [{
        path: "/site-addresses",
        name: "site-addresses",
        component: () => import("@/views/site-addresses/List.vue"),
        meta: {
            auth: true,
            section: "settings",
            allowedPermission: ["readSiteAddresses"],
            module: "siteAddresses"
        }
    }, {
        path: "/site-addresses/create",
        name: "add-site-address",
        component: () => import("@/views/site-addresses/AddEditSiteAddress.vue"),
        meta: {
            auth: true,
            section: "settings",
            allowedPermission: ["createUpdateSiteAddress"],
            module: "siteAddresses"
        }
    }, {
        path: "/site-addresses/edit/:id",
        name: "edit-site-address",
        component: () => import("@/views/site-addresses/AddEditSiteAddress.vue"),
        meta: {
            auth: true,
            section: "settings",
            allowedPermission: ["createUpdateSiteAddress", "updateSiteAddress"],
            module: "siteAddresses"
        }
    }]
}];