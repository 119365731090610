import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/shipping-method",
  component: Layout2,
  children: [{
    path: "/shipping-method",
    name: "shipping-method",
    component: () => import("@/views/shipping-method/List.vue"),
    meta: {
      auth: true,
      allowedPermission: ["readShippingMethod"],
      module: "shippingMethod"
    }
  }, {
    path: "/shipping-method/create",
    name: "add-shipping-method",
    component: () => import("@/views/shipping-method/AddEditShippingMethod.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createShippingMethod"],
      module: "shippingMethod"
    }
  }, {
    path: "/shipping-method/edit/:id",
    name: "edit-shipping-method",
    component: () => import("@/views/shipping-method/AddEditShippingMethod.vue"),
    meta: {
      auth: true,
      allowedPermission: ["updateShippingMethod"],
      module: "shippingMethod"
    }
  }]
}];