const DocMenuConfig = [
    {
      pages: [
        {
          heading: "DASHBOARD",
          route: "/dashboard",
          svgIcon: "media/icons/duotune/art/art002.svg",
          fontIcon: "bi-speedometer2",
        },
      ],
    },
    {
      pages: [
        {
          sectionTitle: "ORDERS",
          sectionKey: "orders",
          fontIcon: "bi-cart bi fs-3",
          sub: [
            {
              heading: "ORDERS",
              route: "/orders",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readOrder"],
            },
/*             {
              heading: "ORDER_PRODUCTS",
              route: "/order-products",
              fontIcon: "bi-app-indicator",
              allowedPermission: "readOrder",
            }, */
            {
              heading: "ARCHIEVE_ORDERS",
              route: "/archive-orders",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readOrder"],
            },
            // {
            //   heading: "JOB_BOARD",
            //   route: "/job-board",
            //   fontIcon: "bi-app-indicator",
            //   allowedPermission: "readOrder",
            // }
          ],
        }
      ],
    },
    {
      pages: [
        {
          sectionTitle: "JOBS",
          sectionKey: "jobs",
          fontIcon: "bi-briefcase fs-3",
          sub: [
            {
              heading: "JOB_ASSIGNMENTS",
              route: "/jobs",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readJob"],
            },
            {
              heading: "Jobs",
              route: "/my-jobs",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["myJobs", "allDevices"],
            },
            {
              heading: "Completed Jobs",
              route: "/completed-jobs",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["myJobs", "allDevices"],
            }
          ],
        },
      ],
    },
    {
      pages: [
        {
          sectionTitle: "SHIPPING",
          sectionKey: "SHIPPING",
          fontIcon: "bi bi-truck fs-3",
          sub: [
            {
              heading: "Shipping Methods",
              route: "/shipping-method",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readShippingMethod"],
            },
            {
              heading: "MASTER_PACKAGES",
              route: "/master-package",
              svgIcon: "media/icons/duotune/art/art002.svg",
              fontIcon: "bi-box2",
              allowedPermission: ["readMasterPackage"]
            },
          ],
        },
      ],
    },
    {
      pages: [
        {
          sectionTitle: "ASSETS",
          sectionKey: "assets",
          fontIcon: "bi-coin",
          sub: [
            {
              heading: "ASSETS",
              route: "/assets",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readAsset"],
            },
            {
              heading: "ASSET_RULES",
              route: "/asset-rules",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readRule"],
            },
            {
              heading: "PRICE_GROUPS",
              route: "/asset-price-groups",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readAsset"],
            }
          ],
        }
      ],
    },
  
    {
      pages: [
        {
          sectionTitle: "QUOTES",
          sectionKey: "quotes",
          fontIcon: "bi-chat-quote",
          sub: [
            {
              heading: "QUOTES",
              route: "/quotes",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readQuote"],
            },
            {
              heading: "CUSTOM_QUOTES",
              route: "/custom-quote",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["createUpdateQuote"],
            }
          ],
        }
      ],
    },
    {
      pages: [
        {
          sectionTitle: "USERS",
          sectionKey: "admin",
          fontIcon: "bi-person-circle",
          sub: [
            {
              heading: "USERS",
              route: "/users",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readUser"]
            },
            {
              heading: "ROLES",
              route: "/roles",
              fontIcon: "bi-app-indicator",
              allowedPermission: ["readRole"]
            },
          ],
        }
      ],
    },
    {
      pages: [
        {
          sectionTitle: "REPORTS",
          sectionKey: "reports",
          fontIcon: "bi-graph-up-arrow",
          sub: [
            {
              heading: "AUDIT_LOGS",
              route: "/audit-logs",
              fontIcon: "bi-person",
              allowedPermission: ["readAuditLog"]
            },
            {
              heading: "JOBS",
              route: "/jobs-report",
              fontIcon: "bi-person",
              allowedPermission: ["readReports"]
            },
            {
              heading: "JOBS_ACTIVITY_REPORT",
              route: "/job-activity-report",
              fontIcon: "bi-person",
              allowedPermission: ["readReports"]
            }
          ],
        }
      ],
    },
    {
      pages: [
        {
          sectionTitle: "WORKFLOW",
          sectionKey: "WORKFLOW",
          fontIcon: "bi-clipboard-data",
          sub: [
            // {
            //   heading: "OPERATIONS",
            //   route: "/operations",
            //   fontIcon: "bi-person",
            //   allowedPermission: ["readOperations"]
            // },
            {
              heading: "JOB_WORKFLOW",
              route: "/job-workflow",
              fontIcon: "bi-person",
              allowedPermission: ["readJobWorkflow"]
            },
            {
              heading: "DEVICE_MANAGEMENT",
              route: "/devices",
              fontIcon: "bi-person",
              allowedPermission: ["readDevices"]
            },
            {
              heading: "ACTIVITY",
              route: "/activity",
              fontIcon: "bi-person",
              allowedPermission: ["readActivity"]
            },
            {
              heading: "ORDER_JOB_STATUS",
              route: "/status",
              fontIcon: "bi-person",
              allowedPermission: ["readStatus"]
            },
            {
              heading: "PREDEFINED_COMMENTS",
              route: "/predefined-comments",
              fontIcon: "bi-person",
              allowedPermission: ["readPredefinedComments"]
            },
            {
              heading: "SHEET_MANAGEMENT",
              route: "/sheets",
              fontIcon: "bi-book",
              allowedPermission: ["readSheets"]
            },
          ],
        }
      ],
    },
    // {
    //   pages: [
    //     {
    //       heading: "DEVICES",
    //       route: "/my-devices",
    //       svgIcon: "media/icons/duotune/art/art002.svg",
    //       fontIcon: "bi bi-pc-display-horizontal",
    //       allowedPermission: ["myDevices", "allDevices"]
    //     },
    //   ],
    // },
    {
      pages: [
       {
        heading: "CONFIGURATION",
        route: "/configuration",
        fontIcon: "bi-gear",
        allowedPermission: ["readStore", "manageSettings", "readSiteAddresses", "readTemplate"]
       }
      ],
    },
    // {
    //   pages: [
    //     {
    //       sectionTitle: "CONFIGURATION",
    //       sectionKey: "CONFIGURATION",
    //       fontIcon: "bi-gear",
    //       sub: [
    //         {
    //           heading: "Stores",
    //           route: "/stores",
    //           fontIcon: "bi-people-fill",
    //           allowedPermission: ["readStore"]
    //         },
    //         {
    //           heading: "SETTINGS",
    //           route: "/configuration-settings",
    //           fontIcon: "bi bi-droplet-fill",
    //           allowedPermission: ["manageSettings"]
    //         },
    //         {
    //           heading: "ADDRESSES",
    //           route: "/site-addresses",
    //           fontIcon: "bi bi-droplet-fill",
    //           allowedPermission: ["readSiteAddresses"]
    //         },
    //         {
    //           heading: "TEMPLATE_MANAGEMENT",
    //           route: "/template-management",
    //           svgIcon: "media/icons/duotune/art/art002.svg",
    //           fontIcon: "bi-file-earmark-pdf bi fs-3",
    //           allowedPermission: ["readTemplate"]
    //         },
    //         // {
    //         //   heading: "OPERATIONS",
    //         //   route: "/operations",
    //         //   fontIcon: "bi-person",
    //         //   allowedPermission: ["readOperations"]
    //         // },
    //         // {
    //         //   heading: "INK_MANAGEMENT",
    //         //   route: "/inks",
    //         //   fontIcon: "bi bi-droplet-fill",
    //         //   allowedPermission: ["readInks"]
    //         // },
    //       ],
    //     }
    //   ],
    // },
  ];
  
  export default DocMenuConfig;
  