import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/",
  component: Layout2,
  children: [{
    path: "500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  }, {
    path: "403",
    name: "403",
    component: () => import("@/views/crafted/authentication/Error403.vue"),
  }]
}];