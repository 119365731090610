//import ConfigurationSettingConfig from "@/core/config/ConfigurationSettingConfig";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class stores extends VuexModule {
  configurationSettingData: Object = {}

  get getConfigurationSettings() {
    return this.configurationSettingData;
  }

  //Common per page mutation
  @Mutation
  [Mutations.SET_CONFIGURATION_SETTINGS](settings: object) {
    this.configurationSettingData = settings;
  }

  //Common pagination per page action
  @Action
  [Actions.CONFIGURATION_SETTINGS](settings: object) {
    this.context.commit(Mutations.SET_CONFIGURATION_SETTINGS, settings);
  }
}