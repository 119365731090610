import objectPath from "object-path";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store/";
import { config } from "@/core/helpers/config";

class LayoutModalService {
  /**
   * @description initialize default layout
   */
  public static init(): void {
    LayoutModalService.initLayout();
    LayoutModalService.initHeader();
    LayoutModalService.initToolbar();
  }

  /**
   * @description init layout
   */
  public static initLayout(): void {
    store.dispatch(Actions.ADD_BODY_ATTRIBUTE, {
      qulifiedName: "id",
      value: "kt_body",
    });

    if (objectPath.get(config.value, "loader.display")) {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading-enabled");
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    }
  }

  /**
   * @description init header
   */
  public static initHeader(): void {
    store.dispatch(
      Actions.ADD_BODY_CLASSNAME,
      "header-tablet-and-mobile-fixed"
    );
  }

  /**
   * @description init toolbar
   */
  public static initToolbar(): void {
    if (!objectPath.get(config.value, "toolbar.display")) {
      return;
    }
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "toolbar-fixed");
    store.dispatch(
      Actions.ADD_BODY_CLASSNAME,
      "toolbar-tablet-and-mobile-fixed"
    );
  }
}

export default LayoutModalService;
