import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/predefined-comments",
  component: Layout2,
  children: [{
    path: "/predefined-comments",
    name: "predefined-comments",
    component: () => import("@/views/predefined-comments/List.vue"),
    meta: {
      auth: true,
      section: "settings",
      allowedPermission: ["readPredefinedComments"],
      module: "predefinedComments"
    }
  }, {
    path: "/predefined-comments/create",
    name: "add-comment",
    component: () => import("@/views/predefined-comments/AddEditComment.vue"),
    meta: {
      auth: true,
      section: "settings",
      allowedPermission: ["createUpdatePredefinedComments"],
      module: "predefinedComments"
    }
  }, {
    path: "/predefined-comments/edit/:id",
    name: "edit-comment",
    component: () => import("@/views/predefined-comments/AddEditComment.vue"),
    meta: {
      auth: true,
      section: "settings",
      allowedPermission: ["createUpdatePredefinedComments", "updatePredefinedComments"],
      module: "predefinedComments"
    }
  }
  ]
}];