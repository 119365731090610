import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, Action, VuexModule } from "vuex-module-decorators";

@Module
export default class stores extends VuexModule {
  userSettingsData: Object = {}

  get getUserSettings() {
    return this.userSettingsData;
  }

  //Common per page mutation
  @Mutation
  [Mutations.SET_USER_SETTINGS](settings: object) {
    this.userSettingsData = settings;
  }

  //Common pagination per page action
  @Action
  [Actions.USER_SETTINGS](settings: object) {
    this.context.commit(Mutations.SET_USER_SETTINGS, settings);
  }
}