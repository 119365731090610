import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import RememberSearchModule from "@/store/modules/RememberSearchModule";
import ConfigurationSettingsModule from "@/store/modules/ConfigurationSettingsModule";
import UserSettingsModule from "@/store/modules/UserSettingsModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ThemeModeModule,
    RememberSearchModule,
    ConfigurationSettingsModule,
    UserSettingsModule,
  },
});

export default store;
