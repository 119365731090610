import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/master-package",
  component: Layout2,
  children: [{
    path: "/master-package",
    name: "master-package",
    component: () => import("@/views/master-package/List.vue"),
    meta: {
      auth: true,
      allowedPermission: ["readMasterPackage"],
      module: "masterPackage",
    }
  }, {
    path: "/master-package/create",
    name: "add-master-package",
    component: () => import("@/views/master-package/AddEditMasterPackage.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateMasterPackage"],
      module: "masterPackage",
    }
  }, {
    path: "/master-package/edit/:id",
    name: "edit-master-package",
    component: () => import("@/views/master-package/AddEditMasterPackage.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateMasterPackage", "updateMasterPackage"],
      module: "masterPackage",
    }
  }]
}];