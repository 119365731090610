import GlobalConfig from "@/core/config/GlobalConfig";
export default {
  "users": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "stores": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "roles": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "predefinedComments": {
    "search": {
      "search": "",
      "user_id": "",
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "status": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "assets": {
    "search": {
      "search": "",
      "pricingMethod": ""
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "assetPriceGroups": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "assetRules": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "orders": {
    "search": {
      "search": "",
      "searchByUser": null,
      "searchByOrderProductStatus": null,
      "orderPredefinedDate": null,
      "searchByOrderDate": null,
      "productionDueDateOptsGroupVal": null,
      "searchByProductionDueDate": null,
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "quotes": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "auditLogs": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "devices": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "operations": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "activity": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "sheets": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "inks": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "jobs": {
    "search": {
      "search": "",
      "store_id": null,
      "filter_by_order_date": null,
      "filter_by_order_due_date": null,
      "order_date_sort_order": null,
      "order_due_date_sort_order": null
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "my-jobs": {
    "search": {
      "search": "",
      "store": null,
      "due_date": null,
      "activity": null,
      "group_by": null,
      "status": null,
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "my-devices": {
    "search": "",
  },
  "template-management": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "job-workflow": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "shippingMethod": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "masterPackage": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "siteAddresses": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "formulas": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "reports": {
    "job" : {
      "search": {
        "search": "",
        "status": "",
        "store": null,
        "group_by": "orders",
        "job_start_date": "",
        "job_end_date": "",
        "date_filter_type": "job_start_date",
        "sort_by_job_id": "desc",
        "sort_by_job_estimated_time": "",
        "sort_by_job_start_time": "",
        "sort_by_job_end_time": ""
      },
    },
    "job-activity" : {
      "search": {
        "search": "",
        "status": "",
        "store": null,
        "group_by": "order_product",
        "job_start_date": "",
        "job_end_date": "",
        "activity": "",
        "sort_by_activity_id": "desc",
      },
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
};