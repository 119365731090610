import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/formulas",
  component: Layout2,
  children: [{
    path: "/formulas",
    name: "formulas",
    component: () => import("@/views/formulas/List.vue"),
    meta: {
      auth: true,
      allowedPermission: ["readDevices"],
      module: "formulas",
      section: "settings",
    }
  }, {
    path: "/formulas/create",
    name: "add-formula",
    component: () => import("@/views/formulas/AddEditFormula.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateDevice"],
      module: "formulas",
      section: "settings",
    }
  }, {
    path: "/formulas/edit/:id",
    name: "edit-formula",
    component: () => import("@/views/formulas/AddEditFormula.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateDevice", "updateDevice"],
      module: "formulas",
      section: "settings",
    }
  }]
}];