
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { getDefaultLanguageData } from "@/composable/i18n.js";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    //Set the default language data in local storage if no language found from localstorage
    const languageData = getDefaultLanguageData();
    const language = localStorage.getItem("lang");
    if (language == null) {
      localStorage.setItem("lang", languageData.defaultLanguageData.short_code);
    }
    
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
