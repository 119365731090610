export default {
  //Available Product types
  availableProductTypes: [
    { id: 0, type: "Custom" },
    { id: 1, type: "Booklet" },
    { id: 2, type: "Envelope" }
  ],
  //Available products for custom quote
  productItems: [
    { value: "Premium Business Cards", text: "Premium Business Cards" },
    { value: "Flyers", text: "Flyers" },
    { value: "Letterhead", text: "Letterhead" },
    { value: "Brochures", text: "Brochures" },
    { value: "Greeting Cards", text: "Greeting Cards" },
    { value: "Posters", text: "Posters" },
    { value: "Door Hangers", text: "Door Hangers" },
    { value: "File Folders", text: "File Folders" },
    { value: "Table Tents", text: "Table Tents" },
    { value: "Folded Business Cards", text: "Folded Business Cards" },
    { value: "Square Business Cards", text: "Square Business Cards" },
    { value: "Pocket Folders", text: "Pocket Folders" },
    { value: "Premium Postcards", text: "Premium Postcards" },
    { value: "Hang Tags", text: "Hang Tags" },
    { value: "Folded Postcards", text: "Folded Postcards" },
    { value: "Jumbo Postcards", text: "Jumbo Postcards" },
    { value: "Notepads", text: "Notepads" },
    { value: "Rack Cards", text: "Rack Cards" },
    { value: "Sales Sheets", text: "Sales Sheets" },
    { value: "Beverage Coasters", text: "Beverage Coasters" },
    { value: "Event Tickets", text: "Event Tickets" },
    { value: "EDDM® Postcards", text: "EDDM® Postcards" },
    { value: "Invitations", text: "Invitations" },
    { value: "Note Cards", text: "Note Cards" },
    { value: "Booklets", text: "Booklets" },
    { value: "Menus", text: "Menus" },
    { value: "custom", text: "Custom" },
  ],
  //Predefined groups of date array
  predefinedDateGroups: [
    { text: "Select", value: "" },
    { text: "Today", value: "today" },
    { text: "Yesterday", value: "yesterday" },
    { text: "This Week", value: "thisweek" },
    { text: "Last Week", value: "lastweek" },
    { text: "This Month", value: "thismonth" },
    { text: "Last Month", value: "lastmonth" },
    { text: "This Year", value: "thisyear" },
    { text: "Last Year", value: "lastyear" },
  ],
  //Pagination common configuration
  paginationConfig: {
    "perPage": 10,
    "pageNumber": 1,
    "pageSize": [10, 25, 50, 100]
  },

  //Site Languages
  siteLanguages:{
    en: {
      name: "English",
      short_code:"en",
      is_default: 1,
      flag: "united-states.svg"
    },
    fr: {
      name: "French",
      short_code:"fr",
      is_default: 0,
      flag: "france.svg"
    },
  },

  //Measurement units
  measurementUnits: [
    { value: "mm", text: "Mm" },
    { value: "cm", text: "Cm" },
    { value: "meter", text: "Meter" },
    { value: "feet", text: "Feet" },
    { value: "inch", text: "Inch" }
  ],
};