import Layout2 from "@/layout/LayoutModal.vue";

export default [{
  path: "/template-management",
  component: Layout2,
  children: [{
    path: "/template-management",
    name: "template-management",
    component: () => import("@/views/template-management/List.vue"),
    meta: {
      auth: true,
      section: "template-management",
      allowedPermission: ["readTemplate"],
      module: "template-management"
    }
  }, {
    path: "create",
    name: "create-template",
    component: () => import("@/views/template-management/AddEditTemplate"),
    meta: {
      auth: true,
      section: "template-management",
      allowedPermission: ["createTemplate"],
      module: "template-management"
    }
  }, {
    path: "edit/:id",
    name: "edit-template",
    component: () => import("@/views/template-management/AddEditTemplate"),
    meta: {
      auth: true,
      section: "template-management",
      allowedPermission: ["createTemplate"],
      module: "template-management"
    }
  }]
}];