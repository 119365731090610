import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/operations",
  component: Layout2,
  children: [{
    path: "/operations",
    name: "operations",
    component: () => import("@/views/operations/List.vue"),
    meta: {
      auth: true,
      allowedPermission: ["readOperations"],
      module: "operations",
      section: "settings"
    }
  }, {
    path: "/operations/create",
    name: "add-operation",
    component: () => import("@/views/operations/AddEditOperation.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateOperation"],
      module: "operations",
      section: "settings"
    }
  }, {
    path: "/operations/edit/:id",
    name: "edit-operation",
    component: () => import("@/views/operations/AddEditOperation.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateOperation", "updateOperation"],
      module: "operations",
      section: "settings"
    }
  }]
}];