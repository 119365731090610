import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/asset-rules",
  component: Layout2,
  children: [{
    path: "/asset-rules",
    name: "asset-rules-list",
    component: () => import("@/views/asset-rules/List.vue"),
    meta: {
      auth: true,
      section: "assets",
      allowedPermission: ["readRule"],
      module: "assetRules"
    }
  }, {
    path: "add",
    name: "add-rule",
    component: () => import("@/views/asset-rules/AddEditRule.vue"),
    meta: {
      auth: true,
      section: "assets",
      allowedPermission: ["createUpdateRule"],
      module: "assetRules"
    }
  }, {
    path: "edit/:id",
    name: "edit-rule",
    component: () => import("@/views/asset-rules/AddEditRule.vue"),
    meta: {
      auth: true,
      section: "assets",
      allowedPermission: ["updateRule", "createUpdateRule"],
      module: "assetRules"
    }
  }
  ]
}];