import { ElNotification } from "element-plus";
import arraySort from "array-sort";
import { Actions } from "@/store/enums/StoreEnums";
import axios from "axios";
import store from "@/store";
import moment from "moment-timezone";
import { Mutations as rememberMutation } from "@/store/enums/RememberSearchEnums";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n/index";

//We have this 4 types of error message :- success , warning , info , error
export function useNotification(Msg, Type = "success", Title = "") {
  if (typeof Msg == "object") {
    let msgString = "";
    Object.values(Msg).forEach((key) => {
      Object.values(key).forEach((message) => {
        msgString += "<p>" + message + "</p>";
      });
    });
    Msg = msgString;
  }
  ElNotification({ dangerouslyUseHTMLString: true, message: Msg, type: Type, title: Title, });
}

//Get login users roles from local storage
export const useLoggedInUserRoles = () => {
  let roles = window.localStorage.getItem("roles");
  return roles;
};

//Get login users permissions from local storage
export const useLoggedInUserPermissions = () => {
  let permissions = window.localStorage.getItem("permissions");
  if (permissions != null) {
    permissions = permissions.split(",");
  }
  return permissions;
};

//Handle column sorting
export function useSort(e, tableData) {
  const reverse = e.order === "asc";
  if (e.label) {
    arraySort(tableData, e.label, { reverse });
  }
}

//Format the text (Replace the dash with space and make first letter of word capital)
export const useFormatText = (string) => {
  return string.toString().replace(/-/g, " ").replace(/_/g, " ").replace(/([a-z])([A-Z])/g, "$1 $2").replace(/(?: |\b)(\w)/g, function (key) {
    return key.toUpperCase();
  });
};

//Error handling
export const useErrorHandling = (error) => {
  let message = "";
  if (typeof error.response !== "undefined") {
    if (error.response.status == 404 || error.response.status == 500) {
      message = error.response.status + " " + error.response.statusText;
    } else {
      message = error.response.data.message;
    }
  } else if (typeof error.message !== "undefined") {
    message = error.message;
  } else {
    message = "Something went wrong";
  }
  useNotification(message, "error");
  return false;
};

//create identifier key based on name
export const useGenerateIdentifierKey = (name) => {
  return name.toLowerCase().replace(/\s/g, "_");
};

//create activities html
export const getActivitiesHtml = (activities) => {
  let activitiesArr = [];
  if (activities && activities.length > 0) {
    let activitiesGroup = activities.reduce(function (r, a) {
      r[a.operation.name] = r[a.operation.name] || [];
      r[a.operation.name].push(a.name);
      return r;
    }, Object.create(null));
    Object.entries(activitiesGroup).forEach((value, index) => {
      activitiesArr[index] = {};
      activitiesArr[index].key = value[0] ? value[0] : "";
      activitiesArr[index].value = value[1] ? value[1] : "";
    });
  };
  let temp = "";
  activitiesArr.forEach((obj) => {
    temp += "<div class='row'><div class='col-md-3'><div class='d-flex align-items-baseline'>";
    temp += "<span class='badge-primary badge mb-2 text-wrap'>" + obj.key + "</span><i class='bi bi-caret-right-fill'></i></div></div>";
    temp += "<div class='col-md-4'><div class='d-flex align-items-baseline'>";
    obj.value.forEach((activity) => {
      temp += "<span class='badge-success badge me-2 mb-2 text-wrap'>" + activity + "</span>";
    });
    temp += "</div></div></div>";
  });
  return temp;
};

//create activities html
export const getActivityDeviceHtml = (activities) => {
  let activitiesArr = [];
  if (activities && activities.length > 0) {
    let activitiesGroup = activities.reduce(function (r, a) {
      r[a.operation.name] = r[a.operation.name] || [];
      r[a.operation.name].push(a);
      return r;
    }, Object.create(null));
    Object.entries(activitiesGroup).forEach((value, index) => {
      activitiesArr[index] = {};
      activitiesArr[index].key = value[0] ? value[0] : "";
      activitiesArr[index].value = value[1] ? value[1] : "";
    });
  };
  let temp = "";
  const { t } = useI18n();
  activitiesArr.forEach((obj) => {
    obj.value.forEach((activity) => {
      temp += "<div class='row'><div class='col-md-3'><div class='d-flex align-items-baseline'><span class='badge-primary badge mb-2 text-wrap' style='word-break: break-all;'>" + obj.key + "</span><i class='bi bi-caret-right-fill'></i></div></div>";
      if (activity.name) {
        temp += "<div class='col-md-3'><div class='d-flex align-items-baseline'><span class='badge-success badge me-2 mb-2 text-wrap' style='word-break: break-all;'>" + activity.name + "</span><i class='bi bi-caret-right-fill'></i></div></div>";
      }
      if (activity.devices && activity.devices.length) {
        temp += "<div class='col-md-6'><div class='d-flex align-items-baseline'>";
        activity.devices.forEach((device) => {
          let deviceBadge = device.is_down ? "danger" : "info";
          let deviceTitle = device.is_down ? t("DOWN_DEVICE") : "";
          temp += "<span title='" + deviceTitle + "' class='badge-" + deviceBadge + " badge me-2 mb-2 text-wrap' style='word-break: break-all;'>" + device.name + "</span>";
        });
        temp += "</div></div>";
      }
      temp += "</div>";
    });
  });
  return temp;
};

//Set configuration setting data on vuex store and set timezone
export const setSettingData = async () => {
  let settingData = {};
  let storageData = JSON.parse(window.localStorage.getItem("user_info"));
  if(storageData && storageData.configuration_settings) {
    settingData =  storageData.configuration_settings;
    await store.dispatch(Actions.CONFIGURATION_SETTINGS, settingData);
    let settings = store.getters.getConfigurationSettings;
    if (settings && settings["TIMEZONE"]) {
        moment.tz.setDefault(settings["TIMEZONE"]);
    }
    let per_page = settings["PER_PAGE"] ? settings["PER_PAGE"] : "";
    if (per_page != "") {
        await store.commit(rememberMutation.SET_DEFAULT_PER_PAGE, Number(per_page));
    }
  }
};

//Get user settings data from the API and set it into Vuex store
export const setUserSettingsData = async () => {
  let userSettings;
  await axios.get("get-current-user")
    .then(async ({ data }) => {
      if (data.data) {
        userSettings = data.data["user_settings"];
        store.dispatch(Actions.USER_SETTINGS, userSettings);
      }
    })
    .catch((error) => {
      useErrorHandling(error);
    });
};

//Store user vise filters data
export const updateUserSettings = async (data) => {
  await ApiService.post("users/save-filters", data)
    .then((response) => {
      if (response.data) {
        let status = response.status;
        let message = response.data.message;
        if (status == 200 || status == 201) {
          useNotification(message, "success");
        } else {
          useNotification(message, "error");
        }
      }
    })
    .catch((error) => {
      let message = error.message;
      useNotification(message, "error");
    });
};

//Get user saved filter data
export const getUserSavedFilterData = (filterKey, data, userData) => {
  if (userData && userData["filters"] && userData["filters"][filterKey]) {
    let filterData = userData["filters"][filterKey];
    data.forEach((options, headerKey) => {
      let key = options.columnLabel;
      if (filterData[key] !== undefined) {
        data[headerKey].visible = filterData[key];
      }
    });
  }
  return data;
};

/**
 * Download order files
 *
 * @param int orderId - order id.
 * @param int storeOrderId - store order id.
 * @param int storeKey - store unique key.
 */
export const downloadOrderFiles = (orderId, storeOrderId, storeKey, elementId = "", elementClass = "") => {
  if (orderId != "" && storeOrderId != "" && storeKey != "") {
    let actionElement = "";
    if (elementId != "") {
      actionElement = document.getElementById(elementId);
      actionElement.classList.remove(elementClass);
      actionElement.classList.add("spinner-border", "spinner-border-sm");
    }
    axios({
    url: "download-order-files",
    method: "POST",
    data: {
      order_id: orderId
    },
    responseType: "arraybuffer",
    }).then((response) => {
      let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      let fileName = storeKey + "#" + storeOrderId + "-" + moment().format("DD-MM-YYYY") + "-" + Date.now() + ".zip";
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    })
    .catch((error) => {
      let response = error.message;
        if (response != "") {
            useNotification("We are preparing file for you, Please try after some time.", "info");
        }
    })
    .finally(() => {
      if (actionElement != "") {
        actionElement.classList.remove("spinner-border", "spinner-border-sm");
        actionElement.classList.add(elementClass);
      }
    });
    return false;
  }
};

/**
 * Download order product wise files
 *
 * @param int orderId - order id.
 * @param int orderProductId - order product id.
 * @param int storeOrderProductId - store order product id.
 * @param int storeKey - store unique key.
 * @param string elementId - element id
 * @param string elementClass - element class
 * @param string fileType - original, print-ready, proof-files
 */
export const downloadOrderProductFiles = (orderId, orderProductId, storeOrderProductId, storeKey, fileType = "", elementId = "", elementClass = "") => {
  if (orderId != "" && orderProductId != "" && storeOrderProductId != "" && storeKey != "") {
    let actionElement = "";
    if (elementId != "") {
      actionElement = document.getElementById(elementId);
      actionElement.classList.remove(elementClass);
      actionElement.classList.add("spinner-border", "spinner-border-sm");
    }
    axios({
      url: "download-order-files",
      method: "POST",
      data: {
        order_id: orderId,
        order_product_id: orderProductId,
        type: fileType
      },
      responseType: "arraybuffer",
    }).then((response) => {
      let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      let fileName = storeKey + "#" + storeOrderProductId +  "-" + moment().format("DD-MM-YYYY") + "-" + Date.now() + ".zip";
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    })
    .catch((error) => {
      let response = error.message;
      if (response != "") {
        useNotification("We are preparing file for you, Please try after some time.", "info");
      }
    })
    .finally(() => {
      if (actionElement != "") {
        actionElement.classList.remove("spinner-border", "spinner-border-sm");
        actionElement.classList.add(elementClass);
      }
    });
    return false;
  }
};


/**
 * Download proof files
 *
 * @param int version_id - version id
 * @param int version_no - version no
 * @param string store_job_data - store name, order id, job id string
 */
export const downloadProofVersionFiles = (orderProductId, version_id = "", version_no, store_job_data, elementId = "", elementClass = "") => {
  if (orderProductId != "") {
    let actionElement = "";
    if (elementId != "") {
      actionElement = document.getElementById(elementId);
      actionElement.classList.remove(elementClass);
      actionElement.classList.add("spinner-border", "spinner-border-sm");
    }
    axios({
    url: "download-proof-files",
    method: "POST",
    data: {
      version_id: version_id,
      order_product_id: orderProductId
    },
    responseType: "arraybuffer",
    }).then((response) => {
      let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      let fileLink = document.createElement("a");
      fileLink.href = fileURL;
      let version = (version_no == "") ? "" : version_no + "-";
      let fileName = store_job_data + "-" + version + moment().format("DD-MM-YYYY") + "-" + Date.now() + ".zip";
      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    })
    .catch((error) => {
      let response = error.message;
        if (response != "") {
            useNotification(response, "error");
        }
    })
    .finally(() => {
        if (actionElement != "") {
          actionElement.classList.remove("spinner-border", "spinner-border-sm");
          actionElement.classList.add(elementClass);
        }
    });
    return false;
  }
};