import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

const ID_TOKEN_KEY = "id_token" as string;
const USER_INFO = "user_info" as string;
const USER_ROLE = "roles" as string;
const USER_PERMISSION = "permissions" as string;
/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description save user info into localStorage
 * @param info: array
 */
export const saveUserInfo = (info: string): void => {
  window.localStorage.setItem(USER_INFO, JSON.stringify(info));
  window.localStorage.setItem(USER_ROLE, info["roles"]);
  window.localStorage.setItem(USER_PERMISSION, info["permissions"]);
};

/**
 * @description get logged in user info from localStorage
 */
export const getLoggedInUserInfo = (): string | null => {
  return window.localStorage.getItem(USER_INFO);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyUserInfo = (): void => {
  window.localStorage.removeItem(USER_INFO);
  window.localStorage.removeItem(USER_ROLE);
  window.localStorage.removeItem(USER_PERMISSION);
  store.dispatch(Actions.USER_SETTINGS, null);
};

export default {
  getToken,
  saveToken,
  destroyToken,
  saveUserInfo,
  getLoggedInUserInfo,
  destroyUserInfo
};
