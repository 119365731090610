import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/job-workflow",
  component: Layout2,
  children: [{
    path: "/job-workflow",
    name: "job-workflow",
    component: () => import("@/views/job-workflow/List.vue"),
    meta: {
      auth: true,
      allowedPermission: ["readJobWorkflow"],
      module: "job-workflow",
      section: "settings"
    }
  }, {
    path: "/job-workflow/create",
    name: "add-job-workflow",
    component: () => import("@/views/job-workflow/AddEditJobWorkflow.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateJobWorkflow"],
      module: "job-workflow",
      section: "settings"
    }
  }, {
    path: "/job-workflow/edit/:id",
    name: "edit-job-workflow",
    component: () => import("@/views/job-workflow/AddEditJobWorkflow.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateJobWorkflow", "updateJobWorkflow"],
      module: "job-workflow",
      section: "settings"
    }
  }, {
    path: "/job-workflow/duplicate/:id",
    name: "duplicate-job-workflow",
    component: () => import("@/views/job-workflow/AddEditJobWorkflow.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateJobWorkflow", "updateJobWorkflow"],
      module: "job-workflow",
      section: "settings"
    }
  }]
}];