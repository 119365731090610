
import { defineComponent, computed } from "vue";
// import KTSearch from "@/layout/header/partials/Search.vue";
// import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
// import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "header-topbar",
  components: {
    // KTSearch,
    // KTNotificationsMenu,
    // KTQuickLinksMenu,
    KTUserMenu,
  },
  setup() {
    const store = useStore();

    const themeMode = computed(() => {
      return store.getters.getThemeMode;
    });

    return {
      themeMode,
    };
  },
});
