import Layout2 from "@/layout/Layout.vue";

export default [
  {
    path: "/customers",
    component: Layout2,
    children: [
      {
        path: "/customers",
        name: "customers-list",
        component: () => import("@/views/customers/List"),
        meta: {
          auth: true,
          section: "website",
          allowedPermission: ["readCustomer"],
          module: "customers",
        },
      },
    ],
  },
];
