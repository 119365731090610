import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/jobs",
  component: Layout2,
  children: [{
    path: "/jobs",
    name: "job-list",
    component: () => import("@/views/jobs/List"),
    meta: {
      auth: true,
      allowedPermission: ["readJob"],
      module: "jobs",
      section: "jobs",
    }
  }, {
    path: "assign-activities/:id",
    name: "job-assign-activities",
    component: () => import("@/views/jobs/AssignActivities"),
    meta: {
      auth: true,
      allowedPermission: ["assignJob"],
      module: "jobs",
      section: "jobs",
    }
  },
  {
    path: "/my-jobs",
    name: "my-job-list",
    component: () => import("@/views/jobs/MyJobList"),
    meta: {
      auth: true,
      allowedPermission: ["myJobs", "allDevices"],
      module: "jobs",
      section: "jobs",
    }
  },
  {
    path: "/completed-jobs",
    name: "completed-job-list",
    component: () => import("@/views/jobs/CompletedJobList"),
    meta: {
      auth: true,
      allowedPermission: ["myJobs", "allDevices"],
      module: "jobs",
      section: "jobs",
    }
  },
  {
    path: "/jobs/:id",
    name: "job-detail",
    component: () => import("@/views/jobs/JobDetail"),
    meta: {
      auth: true,
      allowedPermission: ["readJob", "myJobs", "allDevices"],
      module: "jobs",
      section: "jobs",
    }
  }
  ]
}];