import Layout2 from "@/layout/Layout.vue";

export default [{
    path: "/sheets",
    component: Layout2,
    children: [{
        path: "/sheets",
        name: "sheets",
        component: () => import("@/views/sheets/List.vue"),
        meta: {
            auth: true,
            section: "settings",
            allowedPermission: ["readSheets"],
            module: "sheets"
        }
    }, {
        path: "/sheets/create",
        name: "add-sheet",
        component: () => import("@/views/sheets/AddEditSheet.vue"),
        meta: {
            auth: true,
            section: "settings",
            allowedPermission: ["createUpdateSheet"],
            module: "sheets"
        }
    }, {
        path: "/sheets/edit/:id",
        name: "edit-sheet",
        component: () => import("@/views/sheets/AddEditSheet.vue"),
        meta: {
            auth: true,
            section: "settings",
            allowedPermission: ["createUpdateSheet", "updateSheet"],
            module: "sheets"
        }
    }]
}];