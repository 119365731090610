import Layout2 from "@/layout/Layout.vue";

export default [{
    path: "/inks",
    component: Layout2,
    children: [{
        path: "/inks",
        name: "inks",
        component: () => import("@/views/inks/List.vue"),
        meta: {
            auth: true,
            section: "settings",
            allowedPermission: ["readInks"],
            module: "inks"
        }
    }, {
        path: "/inks/create",
        name: "add-ink",
        component: () => import("@/views/inks/AddEditInk.vue"),
        meta: {
            auth: true,
            section: "settings",
            allowedPermission: ["createUpdateInk"],
            module: "inks"
        }
    }, {
        path: "/inks/edit/:id",
        name: "edit-ink",
        component: () => import("@/views/inks/AddEditInk.vue"),
        meta: {
            auth: true,
            section: "settings",
            allowedPermission: ["createUpdateInk", "updateInk"],
            module: "inks"
        }
    }]
}];